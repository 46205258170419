<template>
  <div class="protfolio">
    <div class="g-container">
      <h1>Portfolio</h1>
      <div class="filter-Img-gallery">
        <ul ref="gBar" @click="portfolio" class="gallery-bar">
          <li class="item active" data-category="all">All</li>
          <li class="item" data-category="components">Components</li>
          <li class="item" data-category="websites">Websites</li>
          <li class="item" data-category="vue">Vue</li>
        </ul>
        <ul class="gallery-imgs">
          <li data-category="components">
            <a
              href="https://bassem-zaki.github.io/product-card/"
              target="_blank"
            >
              <img
                src="@/assets/img/portfolio/product-card.png"
                alt="product card"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a href="https://bassem-zaki.github.io/Navbar1/" target="_blank">
              <img
                src="@/assets/img/portfolio/normal-navbar.png"
                alt="normal navbar"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a href="https://bassem-zaki.github.io/Navbar2/" target="_blank">
              <img
                src="@/assets/img/portfolio/navbar-with-search-box.png"
                alt="navbar with search box"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a
              href="https://bassem-zaki.github.io/Image-Gallery/"
              target="_blank"
            >
              <img
                src="@/assets/img/portfolio/image-gallery.png"
                alt="Image Gallery"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a
              href="https://bassem-zaki.github.io/cool-buttons/"
              target="_blank"
            >
              <img
                src="@/assets/img/portfolio/cool-buttons.png"
                alt="cool buttons"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a href="https://bassem-zaki.github.io/Slider/" target="_blank">
              <img
                src="@/assets/img/portfolio/slider.png"
                alt="slider"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a href="https://bassem-zaki.github.io/carousel/" target="_blank">
              <img
                src="@/assets/img/portfolio/carousel.png"
                alt="carousel"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="components">
            <a href="https://bassem-zaki.github.io/drag-drop/" target="_blank">
              <img
                src="@/assets/img/portfolio/drag-drop.png"
                alt="drag drop"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="websites">
            <a href="https://bassem-zaki.github.io/Resume/" target="_blank">
              <img
                src="@/assets/img/portfolio/old-resume.png"
                alt="old Resume"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="websites">
            <a href="https://bassem-zaki.github.io/Business/" target="_blank">
              <img
                src="@/assets/img/portfolio/business.png"
                alt="Business"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="websites">
            <a href="https://bassem-zaki.github.io/Blog/" target="_blank">
              <img
                src="@/assets/img/portfolio/blog.png"
                alt="blog1"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="websites">
            <a
              href="https://bassem-zaki.github.io/My-Bookings/"
              target="_blank"
            >
              <img
                src="@/assets/img/portfolio/my-bookings.png"
                alt="my bookings"
                draggable="true"
              />
            </a>
          </li>
          <li data-category="vue">
            <h3>Upload soon</h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  methods: {
    portfolio(e) {
      const galleryImgs = document.querySelectorAll(".gallery-imgs li");
      if (e.target.classList.contains("item")) {
        console.log("done");
        this.$refs.gBar.querySelector(".active").classList.remove("active");
        e.target.classList.add("active");
        let categoryName = e.target.getAttribute("data-category");
        galleryImgs.forEach((img) => {
          let fillterImgs = img.getAttribute("data-category");
          if (fillterImgs === categoryName || categoryName === "all") {
            img.classList.add("show");
            img.classList.remove("hide");
          } else {
            img.classList.remove("show");
            img.classList.add("hide");
          }
        });
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.protfolio {
  padding: 50px 0;
  min-height: 100vh;
  .g-container {
    h1 {
      text-align: center;
    }
    .filter-Img-gallery {
      padding: 60px 0;
      min-height: 100vh;
      .gallery-bar {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          margin: 0 6px 6px;
          cursor: pointer;
          padding: 2px 12px;
          border-radius: 30px;
          transition: all 0.3s ease-in-out;
          background-color: #fff;
          color: #000;
          &.active,
          &:hover {
            color: #fff;
            background-color: #000;
          }
        }
      }
      .gallery-imgs {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 15px;
        li {
          cursor: pointer;
          overflow: hidden;
          img {
            width: 100%;
            vertical-align: middle;
            transition: transform 0.3s ease-in-out;
          }
          &.hide {
            display: none;
          }
          &.show {
            display: block;
            animation: show 0.4s ease-in-out;
          }
          @keyframes show {
            0% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(1);
            }
          }
          &:hover img {
            transform: scale(1.1);
          }
        }
        li[data-category="vue"] {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 5px;
        }
      }
    }
  }
}
</style>
